import { BackstageOverrides } from '@backstage/core-components';
import { BackstageTheme, createTheme, genPageTheme, lightTheme, PageTheme, shapes } from '@backstage/theme';

import InterstateRegularWoff2 from '../assets/fonts/EYInterstate-Regular.woff2';

const eyInterstate = {
  fontFamily: 'EYInterstate-Regular',
  fontStyle: 'regular',
  src: `
    local('EYInterstate'),
    local('EYInterstate-Regular'),
    url(${InterstateRegularWoff2}) format('woff2')
  `,
};

export const createCustomThemeOverrides = (theme: BackstageTheme): BackstageOverrides => {
  return {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [eyInterstate],
      },
    }
  };
};

export const pageThemes: Record<string, PageTheme> = {
  home: genPageTheme({ colors: ['#2e2e38', '#2e2e38'], shape: shapes.round }),
};

const eyDarkGray = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      ...lightTheme.palette.primary,
      main: '#2e2e38',
      light: '#2e2e38',
    },
    secondary: {
      ...lightTheme.palette.secondary,
      main: '#2e2e38',
      light: '#2e2e38',
    },
    background: {
      ...lightTheme.palette.background,
      default: '#ffffff',
    },
    text: {
      primary: '#2e2e38',
      secondary: '#2e2e38',
      disabled: '#96969b',
      hint: '#155cb4',
    },
    error: {
      ...lightTheme.palette.error,
      main: '#b9251c',
      light: '#b9251c',
      dark: '#b9251c',
    },
    warning: {
      ...lightTheme.palette.warning,
      main: '#ff9831',
      light: '#ff9831',
      dark: '#ff9831',
    },
    info: {
      ...lightTheme.palette.info,
      main: '#1777cf',
      light: '#1777cf',
      dark: '#1777cf',
    },
    success: {
      ...lightTheme.palette.success,
      main: '#168736',
      light: '#168736',
      dark: '#168736',
    },
    divider: '#e7e7ea',
  },
  defaultPageTheme: 'home',
  pageTheme: pageThemes,
  fontFamily: 'EYInterstate-Regular',
});

export const eyTheme: BackstageTheme = {
  ...eyDarkGray,
  typography: {
    ...eyDarkGray.typography,
    body1: {
      ...eyDarkGray.typography.body1,
      fontSize: 14,
      fontWeight: 300,
      letterSpacing: '0em',
    },
    body2: {
      ...eyDarkGray.typography.body2,
      fontSize: 12,
      lineHeight: 1.5,
    },
    button: {
      ...eyDarkGray.typography.button,
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.5,
    },
    caption: {
      ...eyDarkGray.typography.caption,
      fontSize: 12,
      lineHeight: 1.5,
    }
  },
  overrides: {
    ...eyDarkGray.overrides,
    ...createCustomThemeOverrides(eyDarkGray),
  },
};
