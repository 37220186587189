import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.431747 26.2459V51.5725H20.4003V45.7475H7.91791V41.5685H16.9443V36.2502H7.91791V32.0709H17.9027L14.5901 26.2459H0.431747Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M33.6904 26.2459L29.4504 34.5109L25.2206 26.2459H16.9436L25.6649 41.5685V51.5725H33.1282V41.5685L41.8615 26.2459H33.6904Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M49.3477 0.374023L0 18.6485L49.3477 9.80332V0.374023Z" fill="#FFE600"/>
      </svg>
  );
};

export default LogoIcon;
