import React from "react";
import {
  Header,
} from '@backstage/core-components';
import './SupportPage.css';

const SupportPage = () => (
  <>
    <Header title='EY Software Catalog'/>
    <div className="text-area">
      <h1 className="title is-1">This is the Support Page</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras gravida,
        risus at dapibus aliquet, elit quam scelerisque tortor, nec accumsan eros
        nulla interdum justo. Pellentesque dignissim, sapien et congue rutrum,
        lorem tortor dapibus turpis, sit amet vestibulum eros mi et odio.
      </p>
    </div>
  </>
);

export default SupportPage;